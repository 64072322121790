import React from "react";

export const MagicalLeafICorn = ({
    eyelidClassName,
    stroke = "var(--accent-darker)",
    fill = "none",
    ...props
}) => (
    <svg
        viewBox="0 0 60 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask id="a" fill="#fff">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40 28.5c0 5.247-4.701 9.5-10.5 9.5S19 33.747 19 28.5 23.701 19 29.5 19 40 23.253 40 28.5Zm-12-1c0 1.933-1.343 3.5-3 3.5s-3-1.567-3-3.5 2.343-4.5 4-4.5 2 2.567 2 4.5Zm6 3.5c-1.657 0-3-1.567-3-3.5s.343-4.5 2-4.5 4 2.567 4 4.5c0 1.933-1.343 3.5-3 3.5Z"
            />
        </mask>
        {/* head */}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 28.5c0 5.247-4.701 9.5-10.5 9.5S19 33.747 19 28.5 23.701 19 29.5 19 40 23.253 40 28.5Zm-12-1c0 1.933-1.343 3.5-3 3.5s-3-1.567-3-3.5 2.343-4.5 4-4.5 2 2.567 2 4.5Zm6 3.5c-1.657 0-3-1.567-3-3.5s.343-4.5 2-4.5 4 2.567 4 4.5c0 1.933-1.343 3.5-3 3.5Z"
            fill="var(--background)"
        />
        {/* head outline */}
        <path
            d="M29.5 39C35.755 39 41 34.39 41 28.5h-2c0 4.603-4.157 8.5-9.5 8.5v2ZM18 28.5C18 34.39 23.245 39 29.5 39v-2c-5.343 0-9.5-3.897-9.5-8.5h-2ZM29.5 18C23.245 18 18 22.61 18 28.5h2c0-4.603 4.157-8.5 9.5-8.5v-2ZM41 28.5C41 22.61 35.755 18 29.5 18v2c5.343 0 9.5 3.897 9.5 8.5h2ZM25 32c2.348 0 4-2.165 4-4.5h-2c0 1.53-1.035 2.5-2 2.5v2Zm-4-4.5c0 2.335 1.652 4.5 4 4.5v-2c-.965 0-2-.97-2-2.5h-2Zm5-5.5c-.66 0-1.296.25-1.837.573-.553.33-1.077.78-1.529 1.284C21.762 24.83 21 26.183 21 27.5h2c0-.616.41-1.512 1.123-2.307.341-.38.713-.692 1.066-.904.364-.217.642-.289.811-.289v-2Zm3 5.5c0-1-.086-2.253-.43-3.29C28.24 23.215 27.506 22 26 22v2c.151 0 .417.069.672.84.242.73.328 1.727.328 2.66h2Zm1 0c0 2.335 1.652 4.5 4 4.5v-2c-.965 0-2-.97-2-2.5h-2Zm3-5.5c-1.506 0-2.24 1.215-2.57 2.21-.344 1.037-.43 2.29-.43 3.29h2c0-.933.086-1.93.328-2.66.255-.771.52-.84.672-.84v-2Zm5 5.5c0-1.317-.762-2.671-1.635-3.643a6.775 6.775 0 0 0-1.528-1.284C34.297 22.25 33.66 22 33 22v2c.17 0 .447.072.81.29.353.21.726.523 1.067.903.714.795 1.123 1.69 1.123 2.307h2ZM34 32c2.348 0 4-2.165 4-4.5h-2c0 1.53-1.035 2.5-2 2.5v2Z"
            fill={stroke}
            mask="url(#a)"
        />
        {/* right ear */}
        <path
            d="M37.261 21.711c-6.52-5.83 2.402-17.867 16.32-18.766-.48 13.144-10.235 24.208-16.32 18.766Zm0 0 2.303-2.659m11.13-12.85-3.6 4.157m-7.53 8.693 6.744-.703m-6.744.703 3.928-4.535m-3.928 4.535-.322-6.3m4.25 1.764 4.679-.53m-4.679.53 3.601-4.157m-3.6 4.158-.892-4.8m4.492.642 2.983-.029m-2.983.029-.716-2.64"
            fill={fill}
            strokeLinecap="round"
            stroke={stroke}
        />
        {/* left ear */}
        <path
            d="M22.007 21.44c-6.456 5.9-17.535-4.188-17.03-18.125 13.03 1.8 23.056 12.617 17.03 18.124Zm0 0-2.414-2.56M7.927 6.515l3.774 4.001m7.892 8.366-.021-6.781m.021 6.78-4.117-4.364m4.117 4.365-6.3-.313m2.183-4.052-.057-4.708m.057 4.708L11.7 10.515m3.775 4-4.865.405m1.09-4.405.272-2.97m-.272 2.97-2.698.447"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
        />
    </svg>
);
