export const LEAFS = Object.freeze([
    {
        i: 13,
        stemPath: "M57.5 136.5c-4 2.5-6.8-1.3-10 1.5",
        fillPath:
            "M67.792 143.744c-1.904.107-2.472-.016-4.098-.142.633 3.035-6.923-2.715-7.956-6.515-1.042-3.83 2.492-12.868 3.469-9.986 1.306-.983 1.484-1.651 3.351-2.601 1.868-.95 3.054-.856 3.007.611 1.732.046 6.207-.845 5.732.962 2.02.539 6.673-.341 5.728 1.87 2.078 1.955 4.197.508 4.574 1.896.35 1.285-1.939 1.398-3.032 3.775 1.939 1.443-2.554 2.933-4.022 4.4 1.877 1.399-3 2.827-4.44 3.791 1.2 1.676-.41 1.833-2.313 1.939Z",
        strokePath:
            "m55.738 137.087 3.232-.906m-3.232.906c1.033 3.8 8.589 9.55 7.956 6.515m-7.956-6.515c-1.042-3.83 2.492-12.868 3.469-9.986m22.392 2.738-8.62 2.416m8.62-2.416c-.377-1.388-2.496.059-4.574-1.896m4.574 1.896c.35 1.285-1.939 1.398-3.032 3.775m-19.597 2.567 4.724 7.421m-4.724-7.421 3.951-1.107m-3.95 1.107.236-9.08m4.487 16.501c1.626.126 2.194.249 4.098.142 1.904-.106 3.512-.263 2.313-1.939m-7.184-6.731 7.184 6.731m-7.184-6.731 2.644-9.963m-2.644 9.963 4.974-1.394m2.21 8.125c1.44-.964 6.317-2.392 4.44-3.791m-8.98-12.903c.047-1.467-1.139-1.561-3.007-.611-1.867.95-2.045 1.618-3.351 2.601m6.358-1.99c1.732.046 6.207-.845 5.732.962m-3.401 7.607 6.649 4.334m-6.65-4.334 3.402-7.607m-3.401 7.607 5.083-1.425m1.566 5.759c1.468-1.467 5.96-2.957 4.022-4.4m-7.27-7.541c2.02.539 6.673-.341 5.728 1.87m-4.046 4.312 4.046-4.312m-4.046 4.312 5.588 1.359",
    },
    {
        i: 11,
        stemPath: "M54 114c-3 2.5-4.4 2-4 6",
        fillPath:
            "M66.257 118.308c-1.825.559-2.406.576-4.015.842 1.32 2.799-7.358-.982-9.245-4.427-1.901-3.473-.565-13.103 1.053-10.536 1.042-1.267 1.06-1.959 2.654-3.329 1.594-1.37 2.768-1.562 3.064-.125 1.694-.371 5.836-2.306 5.794-.437 2.088.04 6.405-1.927 6 .447 2.474 1.403 4.197-.511 4.886.749.638 1.165-1.56 1.822-2.07 4.394 2.218.938-1.802 3.461-2.888 5.238 2.15.91-2.26 3.465-3.435 4.746 1.554 1.342.028 1.879-1.798 2.438Z",
        strokePath:
            "m52.997 114.723 2.932-1.654m-2.932 1.654c1.887 3.445 10.564 7.226 9.245 4.427m-9.245-4.427c-1.901-3.473-.565-13.103 1.053-10.536m22.398-2.695-7.817 4.41m7.817-4.41c-.69-1.26-2.412.654-4.886-.749m4.886.749c.638 1.165-1.56 1.822-2.07 4.394m-18.45 7.183 6.314 6.081m-6.313-6.081 3.582-2.022m-3.582 2.022-1.879-8.882m8.192 14.963c1.61-.266 2.19-.283 4.015-.842 1.826-.559 3.352-1.096 1.798-2.438m-8.544-4.823 8.544 4.823m-8.544-4.823.257-10.314m-.257 10.314 4.51-2.544m4.034 7.367c1.176-1.281 5.584-3.836 3.435-4.746m-11.722-10.391c-.296-1.437-1.47-1.246-3.064.125-1.595 1.37-1.612 2.062-2.654 3.329m5.718-3.454c1.694-.371 5.836-2.306 5.794-.437m-1.54 8.207 7.468 2.621m-7.468-2.621 1.54-8.207m-1.54 8.207 4.61-2.601m2.858 5.222c1.086-1.777 5.106-4.3 2.888-5.238m-8.816-5.59c2.088.04 6.405-1.927 6 .447m-2.93 5.159 2.93-5.159m-2.93 5.159 5.745-.016",
    },
    {
        i: 10,
        stemPath: "M39 115.5c4.5-.5 10.7 1.6 11.5 6",
        fillPath:
            "M29.844 107.188c1.9.157 2.447.357 4.04.706-.22-3.094 6.496 3.644 7.012 7.551.52 3.938-4.19 12.406-4.773 9.416-1.425.793-1.69 1.43-3.668 2.115-1.978.684-3.14.427-2.897-1.02-1.71-.284-6.263-.019-5.55-1.743-1.93-.813-6.657-.582-5.425-2.643-1.797-2.223-4.09-1.081-4.279-2.509-.174-1.321 2.109-1.117 3.51-3.322-1.728-1.696 2.922-2.554 4.573-3.804-1.673-1.645 3.35-2.388 4.905-3.144-.963-1.826.651-1.76 2.552-1.603Z",
        strokePath:
            "m40.896 115.445-3.324.452m3.324-.452c-.516-3.907-7.232-10.645-7.012-7.551m7.012 7.551c.52 3.938-4.19 12.406-4.773 9.416m-21.819-5.8 8.864-1.205m-8.864 1.205c.189 1.428 2.482.286 4.279 2.509m-4.279-2.509c-.174-1.321 2.109-1.117 3.51-3.322m19.758.158-3.688-8.003m3.688 8.003-4.063.552m4.063-.552-1.449 8.964m-2.239-16.967c-1.593-.349-2.14-.549-4.04-.706-1.9-.157-3.515-.223-2.552 1.603m6.218 7.658-6.217-7.658m6.217 7.658-3.952 9.507m3.952-9.507-5.115.696m-1.102-8.354c-1.556.756-6.58 1.499-4.906 3.144m7.17 14.021c-.241 1.447.92 1.704 2.898 1.02 1.977-.685 2.243-1.322 3.668-2.115m-6.565 1.095c-1.71-.284-6.263-.019-5.55-1.743m4.387-7.068-6.008-5.21m6.008 5.21-4.388 7.068m4.388-7.068-5.227.711m-.781-5.921c-1.65 1.25-6.301 2.108-4.573 3.804m6.193 8.474c-1.929-.813-6.656-.582-5.424-2.643m4.585-3.714-4.585 3.714m4.585-3.714-5.354-2.117",
    },
    {
        i: 9,
        stemPath: "M42 93.5C39.5 95 35 96 35.5 99",
        fillPath:
            "M52.78 98.807c-1.844.49-2.425.484-4.043.69 1.216 2.846-7.318-1.258-9.077-4.773-1.773-3.542-.086-13.115 1.438-10.488 1.087-1.228 1.13-1.918 2.773-3.227 1.643-1.31 2.824-1.457 3.066-.01 1.707-.306 5.917-2.085 5.806-.218 2.086.119 6.472-1.685 5.98.673 2.42 1.495 4.212-.353 4.855.932.595 1.188-1.625 1.762-2.23 4.313 2.184 1.02-1.926 3.39-3.076 5.126 2.114.99-2.385 3.377-3.606 4.613 1.504 1.4-.041 1.879-1.886 2.369Z",
        strokePath:
            "m39.66 94.724 2.99-1.542m-2.99 1.542c1.76 3.515 10.293 7.619 9.077 4.772m-9.077-4.772c-1.773-3.542-.086-13.115 1.438-10.488m22.48-1.85-7.972 4.113m7.972-4.113c-.643-1.285-2.434.563-4.855-.932m4.855.932c.595 1.188-1.625 1.762-2.23 4.313M42.65 93.182l6.087 6.314m-6.087-6.314 3.654-1.885m-3.654 1.885-1.552-8.946m7.639 15.26c1.618-.205 2.199-.2 4.043-.69 1.845-.49 3.39-.969 1.886-2.368m-8.362-5.141 8.362 5.14m-8.362-5.14.633-10.298m-.633 10.298 4.6-2.373m3.762 7.514c1.221-1.236 5.72-3.623 3.606-4.614M46.938 81c-.243-1.447-1.424-1.3-3.067.01-1.643 1.31-1.686 2-2.773 3.227m5.84-3.237c1.706-.306 5.916-2.085 5.805-.218m-1.839 8.143 7.368 2.9m-7.367-2.9 1.838-8.143m-1.839 8.143 4.702-2.425m2.666 5.326c1.15-1.735 5.26-4.105 3.077-5.126m-8.605-5.918c2.085.119 6.47-1.685 5.98.673m-3.118 5.045 3.117-5.045m-3.117 5.045 5.743.2",
    },
    {
        i: 8,
        stemPath: "M25 95.5c4-.5 8-.2 10 3",
        fillPath:
            "M16.844 86.885c1.9.157 2.447.357 4.04.706-.22-3.094 6.496 3.644 7.012 7.55.52 3.939-4.19 12.407-4.773 9.417-1.425.793-1.69 1.43-3.668 2.114-1.978.685-3.14.427-2.897-1.02-1.71-.283-6.263-.018-5.55-1.743-1.93-.812-6.657-.581-5.425-2.642-1.797-2.223-4.09-1.081-4.279-2.509-.174-1.32 2.109-1.117 3.51-3.322-1.728-1.696 2.922-2.554 4.573-3.805-1.673-1.645 3.35-2.387 4.905-3.143-.963-1.826.651-1.76 2.552-1.603Z",
        strokePath:
            "m27.896 95.142-3.324.452m3.324-.452c-.516-3.907-7.232-10.645-7.012-7.55m7.012 7.55c.52 3.938-4.19 12.406-4.773 9.416m-21.819-5.8 8.864-1.205m-8.864 1.205c.189 1.428 2.482.286 4.279 2.509m-4.279-2.509c-.174-1.32 2.109-1.117 3.51-3.322m19.758.158-3.688-8.003m3.688 8.003-4.063.552m4.063-.552-1.449 8.964m-2.239-16.967c-1.593-.35-2.14-.55-4.04-.706-1.9-.157-3.515-.223-2.552 1.603m6.217 7.658-6.216-7.658m6.216 7.658-3.951 9.506m3.951-9.506-5.114.696m-1.102-8.354c-1.556.756-6.58 1.498-4.906 3.143m7.17 14.021c-.241 1.447.92 1.705 2.898 1.02 1.977-.684 2.243-1.321 3.668-2.114m-6.565 1.094c-1.71-.283-6.263-.018-5.55-1.743m4.387-7.067-6.008-5.21m6.008 5.21-4.388 7.067m4.388-7.067-5.227.71m-.781-5.92c-1.65 1.25-6.301 2.108-4.574 3.804m6.194 8.473c-1.929-.812-6.656-.581-5.424-2.642m4.585-3.714-4.585 3.714m4.585-3.714-5.355-2.117",
    },
    {
        i: 6,
        stemPath: "M26 76c2 1.627 4.5 3 5.5 6",
        fillPath:
            "M25.155 63.698c1.315 1.403 1.59 1.922 2.55 3.26 1.867-2.456 2.452 7.111.272 10.373-2.197 3.288-11.268 6.417-9.74 3.793-1.583-.373-2.2-.077-4.123-.905-1.923-.827-2.62-1.805-1.49-2.72-1.09-1.368-4.658-4.25-2.995-5.054-.905-1.91-4.581-4.937-2.31-5.64.12-2.873-2.34-3.573-1.544-4.765.737-1.103 2.306.593 4.798-.103-.175-2.434 3.855.073 5.907.257-.168-2.359 4.065.486 5.721.974.48-2.013 1.64-.872 2.954.53Z",
        strokePath:
            "m27.977 77.331-2.775-1.911m2.775 1.911c2.18-3.262 1.595-12.83-.272-10.373m.272 10.373c-2.197 3.288-11.268 6.417-9.74 3.793M5.776 62.04l7.4 5.097m-7.4-5.097c-.796 1.193 1.663 1.892 1.544 4.765M5.775 62.04c.737-1.103 2.306.593 4.798-.103M25.202 75.42l2.503-8.462m-2.503 8.462-3.392-2.336m3.392 2.336-6.964 5.704m9.467-14.166c-.96-1.338-1.235-1.857-2.55-3.26-1.314-1.402-2.474-2.543-2.954-.53m-.391 9.916.391-9.916m-.391 9.916-9.186 4.415m9.186-4.415-4.27-2.941m4.661-6.975c-1.656-.488-5.889-3.333-5.721-.974m-3.857 15.305c-1.13.915-.432 1.893 1.491 2.72 1.924.828 2.54.532 4.124.905m-5.614-3.625c-1.09-1.368-4.658-4.25-2.995-5.054m7.91-2.302-1.06-7.95m1.06 7.95-7.91 2.302m7.91-2.302-4.363-3.006m3.304-4.944c-2.052-.183-6.082-2.69-5.907-.256m-.944 10.508c-.905-1.91-4.581-4.937-2.31-5.64m5.857.332-5.857-.332m5.857.332-2.603-5.2",
    },
    {
        i: 5,
        stemPath: "M48 58c-4 1.5-7 1-9 3",
        fillPath:
            "M55.993 64.922c-1.706.067-2.212-.052-3.666-.19.522 2.727-6.156-2.533-7.025-5.95-.876-3.443 2.42-11.479 3.252-8.885 1.183-.86 1.352-1.454 3.037-2.277 1.686-.822 2.745-.72 2.682.593 1.55.066 5.568-.663 5.116.947 1.8.513 5.976-.204 5.098 1.76 1.831 1.782 3.749.518 4.067 1.767.293 1.155-1.757 1.221-2.77 3.332 1.714 1.32-2.328 2.586-3.664 3.877 1.66 1.28-2.726 2.484-4.029 3.325 1.049 1.518-.393 1.635-2.098 1.701Z",
        strokePath:
            "m45.302 58.782 2.907-.762m-2.907.762c.87 3.417 7.547 8.677 7.026 5.95m-7.026-5.95c-.876-3.443 2.42-11.479 3.252-8.885m20 2.79-7.75 2.032m7.75-2.032c-.318-1.249-2.236.015-4.067-1.766m4.067 1.766c.293 1.155-1.757 1.221-2.77 3.332M48.21 58.02l4.118 6.712M48.21 58.02l3.552-.931m-3.552.931.344-8.123m3.775 14.835c1.453.138 1.96.257 3.665.19 1.705-.066 3.147-.183 2.098-1.7m-6.33-6.133 6.33 6.132m-6.33-6.132 2.512-8.876m-2.512 8.876 4.472-1.172m1.858 7.304c1.303-.84 5.69-2.044 4.03-3.325m-7.848-11.683c.063-1.312-.996-1.415-2.682-.593-1.685.823-1.854 1.418-3.038 2.277m5.72-1.684c1.55.066 5.568-.663 5.116.947m-3.156 6.757 5.887 3.979m-5.887-3.98 3.156-6.756m-3.156 6.757 4.57-1.199m1.317 5.178c1.336-1.29 5.378-2.557 3.664-3.877M59.39 49.16c1.8.513 5.976-.204 5.098 1.76m-3.684 3.798 3.684-3.797m-3.684 3.797 4.981 1.301",
    },
    {
        i: 4,
        stemPath: "M35.5 55c2 1.5 3.4 4 3 6",
        fillPath:
            "M34.715 43.8c1.133 1.295 1.364 1.768 2.181 2.995 1.745-2.139 1.978 6.438-.071 9.288-2.066 2.873-10.275 5.387-8.828 3.088-1.405-.383-1.965-.138-3.66-.939-1.697-.8-2.29-1.697-1.252-2.48-.932-1.258-4.037-3.948-2.525-4.615-.752-1.737-3.948-4.559-1.895-5.117.194-2.567-1.985-3.269-1.236-4.31.693-.964 2.044.602 4.294.057-.082-2.182 3.447.186 5.277.415-.078-2.115 3.621.562 5.088 1.05.49-1.785 1.494-.728 2.627.567Z",
        strokePath:
            "M36.825 56.083c2.05-2.85 1.816-11.427.071-9.288m-.071 9.288c-2.066 2.873-10.275 5.387-8.828 3.088m8.828-3.088-.108-.08-2.317-1.717M17.43 41.71l6.465 4.792m-6.465-4.792c-.75 1.042 1.43 1.744 1.236 4.311m-1.236-4.31c.693-.964 2.044.602 4.294.057M34.4 54.287l2.496-7.492M34.4 54.286l-2.963-2.196m2.963 2.196-6.403 4.885m8.9-12.376c-.818-1.227-1.05-1.7-2.182-2.996-1.133-1.295-2.137-2.352-2.627-.567m-.651 8.858.651-8.858m-.651 8.858-8.352 3.662m8.352-3.662-3.73-2.764m4.381-6.094c-1.467-.488-5.166-3.165-5.088-1.05m-3.915 13.57c-1.039.783-.445 1.68 1.251 2.48s2.256.556 3.661.939m-4.912-3.419c-.932-1.258-4.037-3.948-2.525-4.615m7.146-1.811L27 42.182m.706 7.144-7.146 1.811m7.146-1.811L23.894 46.5M27 42.18c-1.83-.228-5.36-2.596-5.277-.414m-1.163 9.37c-.752-1.737-3.948-4.559-1.895-5.117m5.23.48-5.23-.48m5.23.48-2.172-4.733",
    },
    {
        i: 3,
        stemPath: "M56 38.5c-2.5 2-5.5 2.667-6.5 3.5",
        fillPath:
            "M65.378 41.247c-1.4.59-1.86.65-3.112.992 1.264 2.108-5.902-.18-7.667-2.755-1.778-2.595-1.48-10.326.004-8.425.725-1.088.684-1.637 1.839-2.85 1.154-1.215 2.068-1.462 2.415-.349 1.312-.43 4.439-2.299 4.552-.815 1.657-.138 4.919-2.046 4.785-.133 2.069.91 3.282-.745 3.927.196.597.87-1.092 1.569-1.293 3.646 1.83.563-1.154 2.887-1.874 4.381 1.774.547-1.515 2.926-2.345 4.036 1.336.936.17 1.485-1.23 2.076Z",
        strokePath:
            "m54.599 39.484 2.19-1.547m-2.19 1.547c1.765 2.575 8.931 4.863 7.667 2.755m-7.667-2.755c-1.778-2.595-1.48-10.326.004-8.425m17.518-3.951-5.84 4.125m5.84-4.125c-.645-.941-1.858.714-3.927-.196m3.927.196c.597.87-1.092 1.569-1.293 3.646m-14.039 7.183 5.477 4.302m-5.477-4.302 2.677-1.89m-2.677 1.89-2.186-6.878m7.663 11.18c1.253-.341 1.711-.402 3.112-.992 1.401-.591 2.567-1.14 1.231-2.076m-7.143-3.125 7.143 3.125m-7.143-3.125-.609-8.186m.61 8.186 3.37-2.38m3.772 5.505c.83-1.11 4.119-3.49 2.346-4.036M58.856 27.86c-.347-1.113-1.261-.866-2.415.348-1.155 1.215-1.114 1.763-1.839 2.851m4.254-3.199c1.312-.43 4.439-2.299 4.552-.815m-.573 6.621 6.118 1.469m-6.118-1.469.573-6.621m-.573 6.621 3.444-2.433m2.674 3.902c.72-1.494 3.704-3.818 1.874-4.38m-7.419-3.71c1.657-.138 4.919-2.046 4.785-.133m-1.914 4.321 1.914-4.32m-1.914 4.32 4.548-.479",
    },
    {
        i: 2,
        stemPath: "M45 36c.5 0 4 4.4 4 6",
        fillPath:
            "M46.736 26.474c.765 1.33.886 1.783 1.388 2.997 1.887-1.556.618 5.961-1.657 8.083-2.294 2.138-9.88 2.878-8.225 1.13-1.16-.583-1.69-.468-3.03-1.466-1.34-.998-1.704-1.884-.664-2.383-.597-1.261-2.842-4.155-1.41-4.47-.356-1.647-2.658-4.672-.773-4.795.61-2.203-1.169-3.2-.337-3.976.77-.717 1.678.887 3.733.81.303-1.917 2.973.772 4.528 1.295.295-1.857 3.06 1.13 4.255 1.816.734-1.47 1.427-.37 2.192.959Z",
        strokePath:
            "m46.467 37.554-1.805-1.995m1.805 1.995c2.275-2.122 3.544-9.639 1.657-8.083m-1.657 8.083c-2.294 2.138-9.88 2.878-8.225 1.13m-6.214-17.09 4.813 5.32m-4.813-5.32c-.832.775.947 1.773.337 3.976m-.337-3.976c.77-.717 1.678.887 3.733.81m8.9 13.155 3.462-6.088m-3.461 6.088-2.206-2.438m2.206 2.438-6.42 3.125m9.882-9.213c-.502-1.214-.623-1.667-1.388-2.997-.765-1.33-1.458-2.429-2.192-.96m-2.088 7.607 2.088-7.606m-2.088 7.606-7.908 1.714m7.908-1.714-2.777-3.07m4.865-4.536c-1.194-.685-3.96-3.673-4.255-1.816m-5.741 11.136c-1.04.499-.676 1.386.664 2.383 1.34.998 1.87.883 3.03 1.466m-3.694-3.849c-.597-1.261-2.842-4.155-1.41-4.47m6.54-.314.611-6.352m-.61 6.352-6.54.315m6.54-.315-2.838-3.137m3.448-3.215c-1.555-.523-4.225-3.212-4.528-1.295m-2.622 7.962c-.357-1.648-2.659-4.673-.774-4.796m4.476 1.344-4.476-1.344m4.476 1.344-1.08-4.51",
    },
    {
        i: 12,
        stemPath: "M42.5 131.5c3.5 1 5.5 4 4.5 7",
        fillPath:
            "M40.412 118.74c1.413 1.299 1.727 1.795 2.781 3.058 1.682-2.591 2.968 6.905 1.033 10.323-1.95 3.445-10.765 7.251-9.433 4.519-1.607-.252-2.2.09-4.179-.59-1.979-.68-2.746-1.602-1.686-2.6-1.186-1.282-4.957-3.886-3.358-4.814-1.043-1.836-4.93-4.576-2.717-5.449-.092-2.874-2.596-3.385-1.89-4.635.655-1.155 2.343.417 4.777-.466-.353-2.413 3.85-.219 5.91-.191-.34-2.339 4.09.178 5.777.539.33-2.044 1.571-.993 2.985.306Z",
        strokePath:
            "m44.226 132.121-2.907-1.696m2.907 1.696c1.935-3.418.649-12.914-1.033-10.323m1.033 10.323c-1.95 3.445-10.765 7.251-9.433 4.519m-13.83-18.088 7.755 4.523m-7.754-4.523c-.707 1.25 1.797 1.761 1.889 4.635m-1.89-4.635c.655-1.155 2.343.417 4.777-.466m15.579 12.339 1.874-8.627m-1.874 8.627-3.554-2.073m3.554 2.073-6.526 6.215m8.4-14.842c-1.054-1.263-1.368-1.759-2.781-3.058-1.414-1.299-2.655-2.35-2.985-.306m.338 9.918-.338-9.918m.338 9.918-8.837 5.098m8.837-5.098-4.475-2.61m4.137-7.308c-1.688-.361-6.118-2.878-5.777-.539m-2.722 15.555c-1.06.998-.293 1.92 1.686 2.6 1.98.68 2.572.338 4.179.59m-5.865-3.19c-1.186-1.282-4.957-3.886-3.358-4.814m7.72-2.894-1.64-7.847m1.64 7.847-7.72 2.894m7.72-2.894-4.572-2.667m2.932-5.18c-2.06-.028-6.263-2.222-5.91.191m-.17 10.55c-1.043-1.836-4.93-4.576-2.717-5.449m5.865-.112-5.865.112m5.865-.112-2.978-4.989",
    },
    {
        i: 7,
        stemPath: "M39 77.627c-2.5 2.5-5.8 1.673-7 4.873",
        fillPath:
            "M51.032 80.242c-1.714.85-2.284.96-3.83 1.486 1.744 2.547-7.419.23-9.826-2.863-2.426-3.118-2.63-12.84-.627-10.57.828-1.421.736-2.106 2.093-3.72 1.356-1.612 2.485-1.992 3.004-.622 1.613-.642 5.396-3.228 5.65-1.376 2.067-.3 6.017-2.947 5.993-.536 2.663.981 4.061-1.189 4.94-.058.814 1.046-1.251 2.053-1.348 4.674 2.338.564-1.23 3.71-2.021 5.641 2.265.548-1.682 3.788-2.64 5.245 1.747 1.07.325 1.85-1.388 2.7Z",
        strokePath:
            "m37.376 78.865 2.632-2.11m-2.632 2.11c2.407 3.093 11.57 5.41 9.825 2.863m-9.825-2.863c-2.426-3.118-2.63-12.84-.627-10.57m21.68-6.312-7.017 5.627m7.018-5.627c-.88-1.13-2.278 1.04-4.941.058m4.94-.058c.814 1.046-1.251 2.053-1.348 4.674M40.008 76.755l7.193 4.973m-7.193-4.973 3.216-2.58m-3.216 2.58-3.259-8.46m10.452 13.433c1.547-.525 2.117-.636 3.83-1.486 1.714-.85 3.136-1.629 1.39-2.7m-9.197-3.367 9.196 3.368m-9.196-3.368-1.378-10.222m1.379 10.222 4.049-3.247m5.146 6.615c.958-1.457 4.905-4.697 2.64-5.245m-13.214-8.345c-.519-1.37-1.648-.99-3.004.623-1.357 1.613-1.265 2.298-2.093 3.719m5.097-4.342c1.613-.642 5.396-3.228 5.65-1.376m-.222 8.351 7.786 1.37m-7.786-1.37.222-8.35m-.222 8.35 4.138-3.318m3.648 4.688c.79-1.93 4.36-5.077 2.021-5.64m-9.585-4.08c2.067-.301 6.017-2.948 5.993-.537m-2.077 5.57 2.077-5.57m-2.077 5.57 5.67-.954",
    },
    {
        i: 90,
        stemPath: "",
        fillPath: "",
        strokePath: "",
    },
]);
