import React from "react";

export const Sunshine = (props) => (
    <svg
        viewBox="0 0 104 104"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        {...props}
    >
        <circle
            cx="51.5"
            cy="51.5"
            r="19"
            fill="var(--accent-darker)"
            stroke="var(--accent-dark)"
        />
        <path
            d="M61.816 17.955c-.406 1.863-.674 2.378-1.226 3.912 3.05.186-4.42 5.962-8.303 5.962-3.914 0-11.557-5.776-8.56-5.962-.585-1.517-1.172-1.863-1.577-3.912-.406-2.05 0-3.168 1.38-2.738.504-1.658.85-6.205 2.439-5.274 1.049-1.806 1.452-6.52 3.3-5.03C51.679 3.423 50.868 1 52.287 1c1.313 0 .81 2.236 2.778 3.913 1.885-1.49 2.105 3.23 3.107 5.03C60 8.5 60.058 13.576 60.59 15.217c1.91-.717 1.631.874 1.226 2.738Z"
            fill="var(--accent-darker)"
        />
        <path
            d="M52.287 27.83v-3.354m0 3.353c3.883 0 11.353-5.776 8.303-5.962m-8.303 5.962c-3.914 0-11.557-5.776-8.56-5.962M52.287 1v8.943m0-8.943c-1.42 0-.608 2.422-3.018 3.913M52.287 1c1.313 0 .81 2.236 2.778 3.913m-2.778 19.563 8.303-2.609m-8.303 2.609v-4.1m0 4.1-8.56-2.609m16.863 0c.552-1.534.82-2.05 1.226-3.912.405-1.863.684-3.455-1.226-2.738m-8.303 5.16 8.303-5.16m-8.303 5.16-8.757-5.16m8.757 5.16v-5.16m8.303 0c-.533-1.641-.59-6.717-2.418-5.274M43.53 15.217c-1.38-.43-1.786.688-1.38 2.738.405 2.05.992 2.395 1.577 3.912m-.197-6.65c.504-1.658.85-6.205 2.439-5.274m6.318 5.274 5.885-5.274m-5.885 5.274-6.318-5.274m6.318 5.274V9.942m5.885 0c-1.002-1.8-1.222-6.52-3.107-5.03m-9.096 5.03c1.049-1.806 1.452-6.52 3.3-5.03m3.018 5.03-3.018-5.03m3.018 5.03 2.778-5.03"
            stroke="var(--accent-dark)"
        />
        <path
            d="M42.294 85.875c.406-1.864.674-2.379 1.226-3.913C40.47 81.776 47.94 76 51.823 76c3.914 0 11.557 5.776 8.56 5.962.585 1.517 1.172 1.863 1.578 3.913.405 2.05 0 3.167-1.381 2.738-.504 1.657-.85 6.205-2.439 5.273-1.049 1.806-1.452 6.521-3.3 5.03-2.41 1.491-1.599 3.913-3.018 3.913-1.313 0-.81-2.236-2.778-3.912-1.885 1.49-2.105-3.231-3.107-5.03-1.828 1.442-1.885-3.634-2.418-5.274-1.91.716-1.631-.875-1.226-2.738Z"
            fill="var(--accent-darker)"
        />
        <path
            d="M51.823 76v3.354m0-3.354c-3.883 0-11.353 5.776-8.303 5.962M51.823 76c3.914 0 11.557 5.776 8.56 5.962m-8.56 20.867v-8.943m0 8.943c1.42 0 .608-2.422 3.018-3.912m-3.018 3.912c-1.313 0-.81-2.236-2.778-3.912m2.778-19.563-8.303 2.608m8.303-2.608v4.099m0-4.1 8.56 2.61m-16.863 0c-.552 1.533-.82 2.049-1.226 3.912-.405 1.863-.684 3.454 1.226 2.738m8.303-5.16-8.303 5.16m8.303-5.16 8.757 5.16m-8.757-5.16v5.16m-8.303 0c.533 1.64.59 6.716 2.418 5.273m14.642-5.273c1.38.429 1.786-.689 1.38-2.738-.405-2.05-.992-2.396-1.577-3.913m.197 6.65c-.504 1.658-.85 6.206-2.439 5.274m-6.318-5.273-5.885 5.273m5.885-5.273 6.318 5.273m-6.318-5.273v5.273m-5.885 0c1.002 1.8 1.222 6.521 3.107 5.03m9.096-5.03c-1.049 1.806-1.452 6.521-3.3 5.03m-3.018-5.03 3.018 5.03m-3.018-5.03-2.778 5.03"
            stroke="var(--accent-dark)"
        />
        <path
            d="M17.956 42.671c1.865.398 2.381.663 3.918 1.21.173-3.053 5.981 4.392 5.999 8.275.017 3.914-5.725 11.582-5.924 8.586-1.515.592-1.858 1.18-3.906 1.595-2.047.415-3.167.014-2.744-1.368-1.66-.497-6.209-.822-5.284-2.415-1.81-1.041-6.528-1.423-5.045-3.278-1.502-2.403-3.92-1.581-3.926-3-.006-1.313 2.232-.821 3.9-2.796-1.499-1.878 3.221-2.119 5.016-3.129-1.45-1.822 3.625-1.902 5.263-2.441-.725-1.907.868-1.636 2.733-1.239Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m27.873 52.156-3.354.015m3.354-.015c-.018-3.883-5.826-11.328-6-8.276m6 8.276c.017 3.914-5.725 11.582-5.924 8.586M1.044 52.276l8.943-.04m-8.943.04c.006 1.419 2.424.597 3.926 3m-3.926-3c-.006-1.313 2.232-.821 3.9-2.796m19.575 2.691-2.645-8.29m2.645 8.29-4.099.019m4.099-.019-2.57 8.571m-.075-16.862c-1.537-.546-2.053-.811-3.918-1.209-1.865-.397-3.458-.669-2.733 1.239m5.197 8.28-5.197-8.28m5.197 8.28L15.3 60.969m5.121-8.78-5.16.023m-.037-8.302c-1.638.54-6.714.62-5.263 2.441M15.3 60.969c-.423 1.382.697 1.783 2.744 1.368 2.048-.414 2.391-1.003 3.906-1.595m-6.65.227c-1.66-.497-6.209-.822-5.284-2.415m5.245-6.342-5.3-5.861m5.3 5.861-5.245 6.342m5.245-6.342-5.273.024M9.96 46.35c-1.795 1.01-6.515 1.25-5.016 3.13m5.07 9.073c-1.81-1.041-6.527-1.423-5.044-3.278m5.017-3.04-5.017 3.04m5.017-3.04L4.944 49.48"
            stroke="var(--accent-dark)"
        />
        <path
            d="M85.962 61.89c-1.865-.396-2.382-.662-3.918-1.208-.173 3.052-5.982-4.393-5.999-8.276-.017-3.914 5.724-11.582 5.924-8.586 1.514-.592 1.858-1.18 3.906-1.595 2.047-.415 3.167-.014 2.744 1.368 1.66.497 6.208.822 5.284 2.416 1.81 1.04 6.527 1.423 5.045 3.277 1.501 2.403 3.92 1.581 3.926 3 .006 1.314-2.232.822-3.9 2.796 1.499 1.879-3.222 2.12-5.017 3.13 1.451 1.821-3.624 1.901-5.262 2.44.725 1.908-.868 1.636-2.733 1.239Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m76.045 52.406 3.354-.015m-3.354.015c.017 3.883 5.826 11.328 5.999 8.276m-5.999-8.276c-.017-3.914 5.724-11.582 5.924-8.586m20.905 8.467-8.943.04m8.943-.04c-.006-1.42-2.425-.598-3.926-3m3.926 3c.006 1.313-2.232.82-3.9 2.795M79.399 52.39l2.645 8.291m-2.645-8.291 4.099-.018m-4.1.018 2.57-8.571m.076 16.862c1.536.546 2.053.812 3.918 1.209 1.865.397 3.457.669 2.733-1.238m-5.197-8.28 5.197 8.28m-5.197-8.28 5.12-8.78m-5.12 8.78 5.16-.023m.037 8.303c1.638-.54 6.713-.62 5.262-2.442M88.62 43.593c.423-1.382-.697-1.783-2.745-1.368-2.047.414-2.39 1.003-3.905 1.595m6.65-.227c1.66.497 6.208.822 5.284 2.416m-5.245 6.34 5.3 5.862m-5.3-5.861 5.245-6.341m-5.245 6.34 5.273-.023m.026 5.885c1.795-1.01 6.516-1.25 5.017-3.13m-5.071-9.072c1.81 1.04 6.527 1.423 5.045 3.277m-5.017 3.04 5.017-3.04m-5.017 3.04 5.043 2.756"
            stroke="var(--accent-dark)"
        />
        <path
            d="M35.027 21.267c1.022 1.61 1.194 2.165 1.879 3.645 2.3-2.013 1.048 7.347-1.714 10.077-2.784 2.751-12.28 4.016-10.279 1.777-1.483-.668-2.143-.502-3.872-1.674-1.73-1.173-2.227-2.253-.943-2.918-.807-1.533-3.758-5.01-1.973-5.465-.523-2.021-3.552-5.658-1.19-5.897.667-2.754-1.613-3.907-.604-4.905.934-.922 2.149 1.02 4.727.83.293-2.385 3.768.818 5.746 1.394.286-2.312 3.895 1.258 5.426 2.05.855-1.852 1.776-.524 2.797 1.086Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m35.192 34.989-2.358-2.386m2.358 2.386c2.762-2.73 4.014-12.09 1.714-10.077m-1.714 10.077c-2.784 2.751-12.28 4.016-10.279 1.777m-8.582-20.858c2.456 2.483 6.287 6.36 6.287 6.36m-6.287-6.36c-1.009.997 1.27 2.15.605 4.904m-.605-4.905c.934-.922 2.149 1.02 4.727.83m11.776 15.866 4.072-7.692m-4.072 7.692-2.881-2.915m2.881 2.915-7.92 4.163m11.992-11.854c-.685-1.48-.857-2.035-1.879-3.645-1.021-1.61-1.942-2.938-2.797-1.085m-2.277 9.506 2.277-9.506m-2.277 9.506-9.855 2.486m9.855-2.486-3.628-3.67m5.905-5.837c-1.531-.792-5.14-4.362-5.426-2.05m-6.706 14.043c-1.284.665-.787 1.745.943 2.918 1.729 1.172 2.39 1.006 3.872 1.674m-4.815-4.592c-.807-1.533-3.758-5.01-1.973-5.465m8.2-.69.479-7.888m-.479 7.887-8.2.691m8.2-.69-3.707-3.751m4.186-4.137c-1.978-.576-5.453-3.779-5.746-1.394m-2.933 9.972c-.523-2.021-3.552-5.658-1.19-5.897m5.683 1.456-5.682-1.456m5.682 1.456-1.56-5.53"
            stroke="var(--accent-dark)"
        />
        <path
            d="M68.89 83.295c-1.021-1.61-1.193-2.165-1.878-3.644-2.3 2.012-1.048-7.347 1.714-10.077 2.783-2.752 12.279-4.016 10.278-1.777 1.483.667 2.144.5 3.873 1.673 1.73 1.173 2.227 2.253.943 2.918.807 1.533 3.758 5.01 1.973 5.465.523 2.022 3.551 5.659 1.189 5.898-.666 2.754 1.614 3.906.604 4.904-.934.923-2.148-1.02-4.726-.83-.293 2.385-3.768-.818-5.746-1.394-.286 2.312-3.895-1.258-5.427-2.05-.855 1.852-1.775.524-2.797-1.086Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m68.726 69.574 2.357 2.385m-2.357-2.385c-2.762 2.73-4.015 12.089-1.714 10.077m1.714-10.077c2.783-2.752 12.279-4.016 10.278-1.777m8.582 20.858-6.287-6.36m6.287 6.36c1.01-.998-1.27-2.15-.604-4.904m.604 4.904c-.934.923-2.148-1.02-4.726-.83M71.083 71.959l-4.071 7.692m4.071-7.692 2.882 2.915m-2.882-2.915 7.921-4.162M67.012 79.65c.685 1.48.857 2.034 1.878 3.644 1.022 1.61 1.942 2.938 2.797 1.086m2.278-9.507-2.278 9.507m2.278-9.507 9.855-2.486m-9.855 2.486 3.627 3.67m-5.905 5.837c1.532.792 5.141 4.362 5.427 2.05m6.706-14.043c1.284-.665.786-1.745-.943-2.918-1.73-1.172-2.39-1.006-3.873-1.673m4.816 4.591c.807 1.533 3.758 5.01 1.973 5.465m-8.2.691-.48 7.888m.48-7.888 8.2-.69m-8.2.69 3.706 3.75m-4.185 4.138c1.978.575 5.453 3.778 5.746 1.393m2.933-9.972c.523 2.022 3.551 5.659 1.189 5.898m-5.683-1.457 5.683 1.457m-5.683-1.457 1.561 5.53"
            stroke="var(--accent-dark)"
        />
        <path
            d="M82.901 35.22c-1.605 1.027-2.16 1.202-3.636 1.893 2.022 2.292-7.343 1.079-10.084-1.671-2.764-2.772-4.068-12.263-1.82-10.272.66-1.485.491-2.145 1.657-3.88 1.165-1.733 2.243-2.235 2.913-.954 1.53-.814 4.995-3.78 5.457-1.996 2.02-.532 5.643-3.575 5.893-1.214 2.756.654 3.9-1.63 4.901-.625.927.93-1.01 2.153-.81 4.73 2.387.282-.802 3.771-1.37 5.751 2.314.277-1.24 3.9-2.027 5.436 1.856.847.532 1.773-1.074 2.801Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m69.18 35.442 2.376-2.368m-2.375 2.368c2.741 2.75 12.106 3.963 10.084 1.671M69.18 35.442c-2.764-2.772-4.068-12.263-1.82-10.272m20.821-8.67-6.334 6.314m6.334-6.313c-1.002-1.005-2.145 1.279-4.901.625m4.901-.625c.927.93-1.01 2.153-.81 4.73M71.556 33.073l7.709 4.039m-7.71-4.039 2.904-2.894m-2.903 2.894L67.36 25.17m11.905 11.943c1.477-.691 2.03-.866 3.636-1.894 1.606-1.028 2.93-1.954 1.074-2.801M74.46 30.18l9.516 2.238M74.46 30.18l-2.528-9.844m2.528 9.844 3.654-3.643m5.862 5.88c.786-1.534 4.34-5.159 2.028-5.435M71.93 20.336c-.67-1.281-1.748-.78-2.913.955-1.166 1.734-.997 2.394-1.658 3.88m4.572-4.835c1.53-.814 4.994-3.78 5.456-1.996m.725 8.197 7.89.445m-7.89-.445-.725-8.197m.725 8.197 3.735-3.723m4.155 4.168c.567-1.98 3.756-5.469 1.37-5.752m-9.985-2.89c2.02-.532 5.643-3.575 5.893-1.214m-1.433 5.688 1.433-5.688m-1.433 5.688 5.524-1.584"
            stroke="var(--accent-dark)"
        />
        <path
            d="M21.016 69.343c1.606-1.028 2.16-1.202 3.637-1.894-2.022-2.292 7.343-1.079 10.084 1.672 2.763 2.772 4.068 12.262 1.82 10.27-.66 1.486-.492 2.146-1.657 3.88s-2.243 2.236-2.914.956c-1.53.813-4.994 3.778-5.456 1.995-2.02.532-5.644 3.576-5.893 1.215-2.757-.655-3.9 1.63-4.902.624-.926-.93 1.012-2.152.81-4.73-2.386-.282.803-3.77 1.37-5.751-2.313-.276 1.241-3.9 2.027-5.436-1.855-.847-.532-1.773 1.074-2.8Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m34.737 69.12-2.375 2.368m2.375-2.367c-2.742-2.75-12.106-3.964-10.084-1.672m10.084 1.672c2.763 2.772 4.068 12.262 1.82 10.27m-20.822 8.67 6.334-6.313m-6.333 6.313c1.001 1.005 2.144-1.279 4.901-.625m-4.902.625c-.926-.93 1.012-2.152.81-4.73m15.817-11.843-7.71-4.039m7.71 4.04-2.903 2.893m2.903-2.894 4.195 7.904M24.653 67.449c-1.477.692-2.031.866-3.637 1.894-1.606 1.028-2.93 1.954-1.073 2.801m9.516 2.238-9.517-2.238m9.517 2.238 2.527 9.845m-2.527-9.845-3.655 3.643m-5.861-5.88c-.787 1.535-4.341 5.159-2.028 5.435m14.071 6.647c.67 1.28 1.749.778 2.914-.956s.996-2.394 1.657-3.88m-4.57 4.836c-1.53.813-4.995 3.778-5.457 1.995m-.726-8.197-7.89-.445m7.89.445.726 8.197m-.726-8.197-3.735 3.723m-4.154-4.168c-.567 1.98-3.756 5.469-1.37 5.752m9.985 2.89c-2.02.532-5.644 3.576-5.893 1.215m1.432-5.69-1.432 5.69m1.432-5.69-5.523 1.585"
            stroke="var(--accent-dark)"
        />
    </svg>
);
