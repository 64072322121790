import React from "react";

export const LinkedInLogo = (props) => {
    return (
        <svg
            viewBox="0 0 635 540"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M27.8 2.4C17.3 6.2 7.7 15.1 3.8 24.8l-2.3 5.7v475l2.3 5.7c3.1 7.6 10.5 15.6 18.4 19.9l6.3 3.4h480l6.2-3.4c7.7-4.1 14.6-11.3 18.1-18.9l2.7-5.7v-477l-2.7-5.7c-3.5-7.6-10.9-15.3-18.3-19.2l-6-3.1L270 1.3C75.5 1.1 30.8 1.3 27.8 2.4zm105.1 73.5c8.4 2.6 13.7 5.9 20.2 12.8 6.3 6.6 8.9 11.1 11.4 19.7 2.3 7.7 1.6 19.9-1.4 27.8-9.6 24.9-38.7 36.8-62.4 25.5-29.4-13.9-35.8-52.1-12.6-74.7 11.9-11.5 29.5-15.9 44.8-11.1zm253.8 120.7c4.5.8 12 2.8 16.7 4.5 28.5 10.3 43.7 31.6 50.9 71.4 2.2 11.7 2.2 13.9 2.2 98v86h-79l-.6-78c-.5-71-.7-78.5-2.3-84-2.8-9.3-6.6-16.2-11.3-20.6-6.5-6-12.1-8.1-23.5-8.7-22.3-1.1-36 5.8-43.8 22.3-6.4 13.7-6.4 13.5-7 95l-.5 74h-79l-.3-127.8L209 201h77v35.5l2.7-4c13.5-20.2 35.7-34.2 59-37.4 8.4-1.2 28-.4 39 1.5zM159.8 328.7l-.3 127.8h-79l-.3-127.8L80 201h80l-.2 127.7zM581.3 470.5c-4.3 2.1-8.7 5.3-10.9 7.8-14.6 16.7-11.4 42.5 6.6 54.6 7.3 4.8 14 6.5 23.5 5.9 27.2-1.8 42.3-32.7 27.4-56-3.7-5.7-7.4-9-14.3-12.5-4.3-2.3-6.7-2.8-15.1-3.1-9.9-.4-10.1-.3-17.2 3.3zm29 2.8c7.4 3.4 13.1 8.8 16.4 15.5 2.4 4.8 2.8 7 2.8 13.7 0 6.6-.5 9-2.7 13.7-11.7 25-46.6 25.2-58.8.3-3.4-6.8-3.5-19.7-.4-26.6 7.7-16.6 26.6-24 42.7-16.6z" />
            <path d="M585 503v19h2.5c2.5 0 2.5-.1 2.5-8.5V505h3.4c3.1 0 3.7.6 8.7 8.5 4.7 7.4 5.7 8.5 8.2 8.5 3.2 0 3.4.6-3.4-9.7l-4.8-7.1 2.7-.7c6.5-1.6 9.6-8.3 6.8-14.8-2-4.8-4.5-5.7-16.7-5.7H585v19zm20.2-13.3c1 .9 1.8 3 1.8 4.9 0 4.7-2.9 6.4-10.7 6.4H590v-13h6.7c4.8 0 7.2.5 8.5 1.7z" />
        </svg>
    );
};
