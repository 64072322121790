import React, { useContext } from "react";
import { NightContext } from "../..";
import { Sunshine } from "../../svgs/Sunshine";
import { WaningCrescent } from "../../svgs/WaningCrescent";
import { classnames } from "../../utils/classnames";
import styles from "./SunshineToggle.module.scss";

export const SunshineToggle = ({ className, ...props }) => {
    const { isNight, setIsNight } = useContext(NightContext);

    const SunshineMode = () => {
        return (
            <div className={null}>
                <Sunshine className={styles.sunshine} />
                <WaningCrescent className={styles.moonlight} />
            </div>
        );
    };

    return (
        <div
            {...props}
            onClick={() => {
                setIsNight((prev) => !prev);
            }}
            className={classnames(className, styles.container, {
                [styles["sunshine-time"]]: isNight,
                [styles["night-time"]]: !isNight,
            })}
        >
            <SunshineMode />
        </div>
    );
};
