import React, { useEffect, useRef, useState } from "react";
import { LEAFS } from "../../svgs/Leafs";
import { classnames } from "../../utils/classnames";
import styles from "./Leafy.module.scss";

export const Leafy = ({ className, onLeafMurder, style = {} }) => {
    const viewboxHeight = 147;
    const svgRef = useRef();
    const [actualOverViewbox, setActualOverViewbox] = useState();

    useEffect(() => {
        const isDeviceThatSupportsHover =
            window.matchMedia("(hover: hover)")?.matches;

        let t;

        /* If this device has hover, I assume it is a desktop
		and for this media query, I've added a vine crawl entry
		animation that takes 2s. So set this value after that time
		so that the other stuff can be calculated correctly */
        if (isDeviceThatSupportsHover) {
            t = setTimeout(() => {
                setActualOverViewbox(
                    svgRef.current.clientHeight / viewboxHeight
                );
            }, 2000);
        } else {
            setActualOverViewbox(svgRef.current.clientHeight / viewboxHeight);
        }

        return () => {
            clearTimeout(t);
        };
    }, []);

    /* These are separated so that I could add anims 
	on the containing div, the vine crawl entry thing */
    const divStyle = { ...style };
    delete divStyle.transform;

    const svgStyle = { ...style };
    delete svgStyle.position;
    delete svgStyle.right;
    delete svgStyle.top;

    return (
        <div
            className={classnames(styles["leaf-me-be"], className)}
            style={divStyle}
        >
            <svg
                viewBox={`0 0 85 ${viewboxHeight}`}
                xmlns="http://www.w3.org/2000/svg"
                style={svgStyle}
                ref={svgRef}
                pointerEvents="none"
            >
                {LEAFS.map((props) => (
                    <LeafGroup
                        {...props}
                        actualOverViewbox={actualOverViewbox}
                        key={props.i}
                        onLeafMurder={onLeafMurder}
                    />
                ))}
                <path
                    d="M52.5 17.242c-.136 6.339-.581 19.288-4.075 26.902-6.128 13.35-14.259 19.986-16.348 35.307-1.648 12.084 1.977 18.805 9.636 27.69 5.32 6.173 8.626 11.294 9.712 19.231.863 6.311-4.221 14.113-10.853 15.796"
                    className={styles["stem"]}
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <LeafGroup
                    fillPath="M58.81 12.126c-.262 1.223-.436 1.561-.793 2.568 1.972.122-2.857 3.912-5.367 3.912-2.53 0-7.47-3.79-5.533-3.912-.379-.996-.758-1.223-1.02-2.568s0-2.078.892-1.797c.326-1.087.55-4.072 1.577-3.46.678-1.185.938-4.28 2.133-3.301C52.257 2.589 51.732 1 52.649 1c.85 0 .525 1.467 1.797 2.568 1.218-.979 1.36 2.12 2.008 3.3 1.182-.946 1.22 2.385 1.563 3.461 1.235-.47 1.055.575.793 1.797Z"
                    strokePath="M52.65 18.606v-2.2m0 2.2c2.51 0 7.34-3.79 5.367-3.912m-5.367 3.912c-2.53 0-7.47-3.79-5.533-3.912M52.65 1v5.869m0-5.869c-.918 0-.393 1.59-1.951 2.568M52.649 1c.85 0 .525 1.467 1.797 2.568M52.65 16.406l5.367-1.712m-5.367 1.712v-2.69m0 2.69-5.533-1.712m10.9 0c.357-1.007.53-1.345.793-2.568.262-1.222.442-2.267-.793-1.797m-5.367 3.387 5.367-3.387m-5.367 3.387-5.66-3.387m5.66 3.387v-3.387m5.367 0c-.344-1.076-.381-4.407-1.563-3.46m-9.465 3.46c-.892-.281-1.154.452-.892 1.797.262 1.345.641 1.572 1.02 2.568m-.128-4.365c.326-1.087.55-4.072 1.577-3.46m4.084 3.46 3.804-3.46m-3.804 3.46-4.084-3.46m4.084 3.46V6.87m3.804 0c-.648-1.181-.79-4.28-2.008-3.301m-5.88 3.3c.678-1.184.938-4.279 2.133-3.3m1.95 3.3-1.95-3.3m1.95 3.3 1.797-3.3"
                    i="1"
                    actualOverViewbox={actualOverViewbox}
                    onLeafMurder={onLeafMurder}
                />
            </svg>
        </div>
    );
};

const LeafGroup = ({
    actualOverViewbox,
    fillPath: fillPathD,
    strokePath: strokePathD,
    stemPath: stemPathD,
    i: leafIdNum,
    onLeafMurder,
    ...props
}) => {
    const leafOutlineFillRef = useRef();
    const [hasDied, setHasDied] = useState();
    const [distanceToBottom, setDistanceToBottom] = useState();

    if (!hasDied && actualOverViewbox) {
        props.onClick = () => {
            setHasDied(true);
            onLeafMurder && onLeafMurder();
        };
    }

    useEffect(() => {
        if (!actualOverViewbox) return;

        // Set the distance to bottom
        const bottom =
            leafOutlineFillRef.current?.getBoundingClientRect()?.bottom;

        if (!bottom) return;

        const adjustedPxToBottom = Math.round(
            (window.innerHeight - bottom) / actualOverViewbox
        );

        setDistanceToBottom(adjustedPxToBottom);
    }, [actualOverViewbox]);

    return (
        <g
            className={classnames(
                styles["leaf-group"],
                styles[`leaf-${leafIdNum}`]
            )}
            {...props}
        >
            {stemPathD && (
                <path className={styles["leaf-stem"]} d={stemPathD} />
            )}
            <g
                className={classnames(styles["leaf-outline-fill"], {
                    [styles.dead]: hasDied,
                })}
                ref={leafOutlineFillRef}
                style={{
                    "--distance-to-bottom": distanceToBottom
                        ? `-${Math.abs(distanceToBottom)}px`
                        : null,
                }}
            >
                <path d={fillPathD} className={styles["leaf-fill"]} />
                <path d={strokePathD} className={styles["leaf-stroke"]} />
            </g>
        </g>
    );
};
