import React from "react";

export const WaningCrescent = (props) => (
    <svg viewBox="0 0 50 57" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12.863 26.814c.7.691.85.95 1.366 1.615.924-1.278 1.376 3.573.303 5.265-1.082 1.706-5.71 3.44-4.963 2.082-.823-.164-1.136-.004-2.14-.394-1.006-.39-1.38-.875-.812-1.358-.583-.678-2.469-2.085-1.624-2.52-.497-.956-2.44-2.435-1.28-2.828.017-1.462-1.262-1.778-.87-2.396.363-.572 1.198.264 2.472-.13-.128-1.232 1.99-.023 3.05.037-.123-1.195 2.104.183 2.966.404.216-1.03.833-.469 1.532.223Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m14.532 33.694-1.461-.927m1.461.927c1.073-1.692.621-6.543-.303-5.265m.303 5.265c-1.082 1.706-5.71 3.44-4.963 2.082M2.843 26.28l3.896 2.471M2.843 26.28c-.392.618.887.934.87 2.396m-.87-2.396c.363-.572 1.198.264 2.472-.13m7.756 6.617 1.158-4.338m-1.158 4.338-1.786-1.132m1.786 1.132-3.502 3.009m4.66-7.347c-.516-.665-.666-.924-1.366-1.615-.7-.692-1.316-1.253-1.532-.223m-.046 5.044.046-5.044m-.046 5.044-4.668 2.389m4.668-2.39L9.037 30.21m2.294-3.618c-.861-.221-3.089-1.599-2.965-.404m-1.75 7.837c-.568.483-.193.968.812 1.358 1.005.39 1.318.23 2.141.394m-2.952-1.752c-.583-.678-2.469-2.085-1.624-2.52m4.044-1.295-.671-4.022m.67 4.022-4.043 1.295m4.044-1.295L6.739 28.75m1.627-2.564c-1.061-.06-3.18-1.27-3.05-.036m-.323 5.353c-.497-.956-2.44-2.435-1.28-2.828m3.026.075-3.025-.075m3.025.075-1.424-2.6"
            stroke="var(--accent-dark)"
        />
        <path
            d="M37.54 15.481c-.876.03-1.135-.03-1.88-.1.243 1.345-3.14-1.263-3.557-2.95-.42-1.701 1.344-5.66 1.748-4.378.616-.421.708-.714 1.581-1.117.873-.402 1.416-.35 1.372.298.796.036 2.866-.316 2.62.478.92.256 3.072-.09 2.604.878.925.883 1.92.263 2.073.88.14.57-.913.599-1.452 1.638.869.655-1.219 1.272-1.916 1.906.841.635-1.423 1.22-2.1 1.632.526.751-.216.806-1.092.835Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m32.103 12.43 1.5-.37m-1.5.37c.416 1.688 3.8 4.296 3.557 2.95m-3.557-2.95c-.42-1.7 1.344-5.658 1.748-4.377m10.25 1.417-4 .987m4-.987c-.152-.617-1.148.003-2.073-.88m2.073.88c.14.57-.913.599-1.452 1.638m-9.046.952 2.057 3.32m-2.057-3.32 1.833-.452m-1.833.452.248-4.007m1.809 7.327c.745.071 1.004.13 1.88.101.877-.03 1.62-.084 1.094-.835m-3.198-3.038 3.198 3.038m-3.198-3.038 1.368-4.374m-1.368 4.374 2.307-.57m.89 3.608c.677-.412 2.941-.997 2.1-1.632m-3.928-5.78c.043-.647-.5-.7-1.373-.298-.873.403-.965.696-1.58 1.117m2.953-.82c.795.037 2.865-.315 2.62.479m-1.681 3.327 2.989 1.975m-2.99-1.975 1.681-3.327m-1.68 3.327 2.358-.582m.63 2.557c.698-.634 2.786-1.25 1.917-1.906m-3.225-3.397c.92.257 3.072-.088 2.604.88m-1.926 1.866 1.926-1.867m-1.926 1.867 2.547.651"
            stroke="var(--accent-dark)"
        />
        <path
            d="M40.546 17.351c-.366-.576-.428-.773-.675-1.3-.806.694-.392-2.603.574-3.548.975-.953 4.323-1.332 3.624-.555.525.246.758.192 1.372.618.615.427.794.812.342 1.038.29.547 1.343 1.796.714 1.944.191.718 1.272 2.024.438 2.092-.226.968.582 1.391.229 1.737-.327.319-.762-.376-1.671-.327-.096.84-1.334-.316-2.034-.533-.093.815-1.379-.472-1.922-.763-.296.649-.625.173-.99-.403Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m40.445 12.503.84.86m-.84-.86c-.966.945-1.38 4.242-.574 3.547m.574-3.547c.975-.953 4.323-1.332 3.624-.555m3.095 7.429-2.24-2.292m2.24 2.292c.353-.346-.455-.769-.229-1.737m.229 1.737c-.327.319-.762-.376-1.671-.327m-4.208-5.688-1.413 2.688m1.413-2.688 1.027 1.05m-1.027-1.05 2.784-1.414m-4.198 4.102c.247.528.31.725.675 1.301.366.576.695 1.052.99.403m.776-3.342-.775 3.342m.775-3.342 3.471-.808m-3.471.808 1.292 1.322m-2.067 2.02c.543.291 1.829 1.578 1.922.763m2.324-4.913c.452-.226.273-.611-.342-1.038-.614-.426-.847-.372-1.372-.618m1.714 1.656c.29.547 1.343 1.796.714 1.944m-2.893.186-.145 2.783m.145-2.783 2.893-.186m-2.893.186 1.32 1.351m-1.465 1.432c.7.217 1.938 1.374 2.034.533m1.004-3.502c.191.718 1.272 2.024.438 2.092m-2.01-.555 2.01.555m-2.01-.555.568 1.965"
            stroke="var(--accent-dark)"
        />
        <path
            d="M34.948 14.089c-.023-.682.023-.883.078-1.463-1.047.19.981-2.443 2.293-2.767 1.323-.327 4.402 1.044 3.405 1.359.329.479.557.55.87 1.229.313.679.272 1.102-.232 1.068-.027.618.247 2.228-.37 2.037-.2.716.07 2.39-.683 2.025-.686.72-.204 1.495-.684 1.613-.444.11-.466-.71-1.275-1.129-.509.676-.989-.948-1.482-1.49-.494.655-.95-1.106-1.27-1.632-.584.409-.627-.168-.65-.85Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m37.32 9.859.288 1.166M37.32 9.86c-1.313.324-3.34 2.957-2.294 2.767m2.293-2.767c1.323-.327 4.402 1.044 3.405 1.359m-1.099 7.972-.768-3.11m.768 3.11c.48-.118-.002-.893.684-1.613m-.684 1.613c-.444.11-.466-.71-1.275-1.129m-.742-7.036-2.582 1.6m2.582-1.6.352 1.426m-.352-1.426 3.116.193m-5.698 1.408c-.055.58-.101.781-.078 1.463.023.682.066 1.259.65.85m2.362-2.488-2.362 2.488m2.362-2.488 3.402 1.063m-3.402-1.063.443 1.795m-2.805.693c.32.526.776 2.287 1.27 1.632m4.494-3.057c.504.035.545-.388.232-1.067-.313-.68-.541-.75-.87-1.23m.639 2.297c-.028.619.246 2.23-.371 2.038m-2.589-1.306-1.535 2.325m1.535-2.325 2.589 1.306m-2.589-1.306.454 1.834m-1.989.491c.493.542.973 2.166 1.482 1.49m2.642-2.509c-.2.716.07 2.39-.683 2.025m-1.452-1.497 1.452 1.497m-1.452-1.497-.507 1.981"
            stroke="var(--accent-dark)"
        />
        <path
            d="M30.852 12.655c-.946.235-1.242.23-2.068.327.577 1.406-3.705-.644-4.549-2.38-.85-1.751.148-6.461.885-5.162.571-.602.603-.941 1.458-1.581.856-.64 1.458-.709 1.56.005.873-.145 3.042-1.008 2.958-.089 1.06.066 3.318-.809 3.033.351 1.21.745 2.149-.16 2.457.475.285.587-.853.863-1.197 2.117 1.095.51-1.03 1.665-1.641 2.515 1.062.495-1.263 1.656-1.902 2.261.745.694-.049.926-.994 1.16Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m24.235 10.601 1.544-.75m-1.544.75c.844 1.737 5.126 3.787 4.55 2.38m-4.55-2.38c-.85-1.75.148-6.46.885-5.161M36.586 4.6l-4.117 2m4.117-2c-.308-.635-1.247.27-2.457-.475m2.457.475c.285.587-.853.863-1.197 2.117m-9.61 3.133 3.005 3.13m-3.005-3.13 1.887-.916m-1.887.916-.66-4.411m3.665 7.542c.826-.096 1.122-.092 2.068-.327.945-.235 1.739-.467.994-1.16m-4.18-2.56 4.18 2.56m-4.18-2.56.473-5.071m-.473 5.07 2.376-1.153m1.804 3.713c.64-.605 2.963-1.766 1.902-2.26m-5.61-5.37c-.102-.714-.704-.645-1.56-.005-.855.64-.887.98-1.458 1.58m3.019-1.575c.872-.145 3.04-1.008 2.957-.089m-1.054 4.006 3.706 1.452m-3.706-1.452 1.054-4.006m-1.054 4.006 2.427-1.18m1.279 2.632c.61-.85 2.736-2.005 1.64-2.515m-4.292-2.943c1.06.066 3.318-.809 3.033.351m-1.66 2.475 1.66-2.475m-1.66 2.475 2.92.117"
            stroke="var(--accent-dark)"
        />
        <path
            d="M24.254 16.986c-.673-.704-.816-.963-1.308-1.633-.935 1.198-1.279-3.537-.189-5.13 1.099-1.607 5.684-3.076 4.921-1.792.805.198 1.116.057 2.096.483s1.338.915.77 1.358c.558.685 2.382 2.14 1.543 2.524.468.952 2.347 2.48 1.199 2.808-.047 1.42 1.204 1.786.806 2.369-.369.539-1.172-.313-2.433.01.1 1.204-1.956-.07-2.997-.178.096 1.167-2.064-.275-2.907-.53-.233.99-.827.416-1.5-.288Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m22.757 10.223 1.417.968m-1.417-.969c-1.09 1.595-.746 6.33.189 5.131m-.189-5.13c1.099-1.607 5.684-3.076 4.921-1.792m6.414 9.542-3.778-2.584m3.778 2.584c.398-.583-.853-.95-.806-2.37m.806 2.37c-.369.539-1.172-.313-2.433.01m-7.485-6.792-1.228 4.162m1.228-4.162 1.732 1.184m-1.732-1.184 3.504-2.76m-4.732 6.922c.492.67.635.929 1.308 1.633.674.705 1.268 1.28 1.501.288m.15-4.899-.15 4.9m.15-4.9 4.639-2.103m-4.638 2.103 2.18 1.491m-2.33 3.408c.842.256 3.002 1.698 2.906.531m1.882-7.533c.568-.443.21-.932-.77-1.358-.98-.426-1.29-.285-2.096-.483m2.866 1.84c.558.686 2.382 2.142 1.543 2.525m-4.001 1.07.576 3.94m-.576-3.94 4-1.07m-4 1.07 2.228 1.523m-1.652 2.416c1.041.109 3.098 1.382 2.997.178m.428-5.187c.468.952 2.347 2.48 1.199 2.808m-2.972-.215 2.972.215m-2.972-.215 1.345 2.594"
            stroke="var(--accent-dark)"
        />
        <path
            d="M22.857 41.456c-.914.339-1.208.367-2.018.554.73 1.334-3.754-.227-4.785-1.86-1.04-1.645-.572-6.437.305-5.228.5-.66.495-1.002 1.274-1.733.779-.73 1.37-.866 1.55-.168.852-.242 2.911-1.34 2.93-.417 1.06-.053 3.207-1.173 3.053.012 1.285.605 2.118-.398 2.495.199.349.551-.752.952-.955 2.237 1.146.385-.838 1.768-1.35 2.681 1.11.374-1.072 1.787-1.64 2.459.817.607.055.925-.859 1.264Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m16.053 40.15 1.451-.917m-1.45.917c1.031 1.633 5.515 3.194 4.785 1.86m-4.785-1.86c-1.04-1.645-.572-6.437.305-5.228m11.302-2.107-3.87 2.445m3.87-2.445c-.377-.597-1.21.406-2.495-.2m2.495.2c.349.551-.752.952-.955 2.237m-9.202 4.181 3.335 2.777m-3.335-2.777 1.774-1.12m-1.774 1.12-1.145-4.311m4.48 7.088c.81-.187 1.104-.215 2.018-.554.914-.34 1.676-.657.859-1.264m-4.438-2.08 4.438 2.08m-4.438-2.08-.094-5.091m.094 5.091 2.232-1.41m2.206 3.49c.568-.672 2.75-2.085 1.64-2.459m-6.172-4.712c-.181-.698-.773-.563-1.551.168-.78.73-.773 1.072-1.274 1.733m2.825-1.901c.85-.242 2.91-1.34 2.93-.417m-.604 4.098 3.845 1.032m-3.845-1.032.603-4.098m-.603 4.098 2.282-1.442m1.563 2.474c.512-.914 2.497-2.297 1.351-2.682m-4.593-2.448c1.06-.053 3.207-1.173 3.053.012m-1.374 2.644 1.374-2.644m-1.374 2.644 2.914-.208"
            stroke="var(--accent-dark)"
        />
        <path
            d="M21.436 32.122c-.832.508-1.115.592-1.875.93.97 1.17-3.728.493-5.053-.912-1.334-1.416-1.79-6.21-.698-5.19.365-.744.294-1.078.919-1.944.625-.866 1.18-1.112 1.49-.462.79-.4 2.601-1.87 2.796-.968 1.03-.255 2.924-1.764 3-.572 1.376.349 2.002-.795 2.486-.282.448.476-.556 1.079-.51 2.38 1.198.158-.485 1.895-.814 2.89 1.161.154-.71 1.957-1.14 2.725.92.44.231.898-.6 1.405Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m14.508 32.14 1.25-1.177m-1.25 1.177c1.325 1.405 6.024 2.081 5.053.912m-5.053-.912c-1.334-1.416-1.79-6.21-.698-5.19m10.69-4.227-3.33 3.139m3.33-3.14c-.483-.513-1.109.63-2.486.282m2.487-.282c.448.476-.556 1.079-.51 2.38m-8.234 5.861 3.804 2.089m-3.804-2.089 1.527-1.439m-1.527 1.439L13.81 26.95m5.75 6.102c.76-.338 1.044-.422 1.876-.93.832-.507 1.52-.965.602-1.405m-4.754-1.193 4.754 1.193m-4.754-1.193-1.064-4.98m1.064 4.98 1.922-1.811m2.832 3.004c.43-.768 2.3-2.57 1.14-2.726m-6.959-3.447c-.31-.65-.865-.404-1.49.462-.625.866-.554 1.2-.92 1.944m2.41-2.406c.79-.4 2.601-1.87 2.796-.968m.191 4.137 3.971.278m-3.971-.278-.19-4.137m.19 4.137 1.964-1.851m2.007 2.13c.329-.995 2.012-2.732.814-2.89m-4.976-1.526c1.03-.255 2.924-1.764 3-.572m-.845 2.858.844-2.858m-.844 2.858 2.821-.76"
            stroke="var(--accent-dark)"
        />
        <path
            d="M24.25 17.83c-.651 1.087-.926 1.355-1.596 2.204 1.825.758-3.99 2.824-6.365 2.009-2.393-.822-5.817-6.062-3.945-5.55-.03-1.077-.313-1.418-.118-2.793.195-1.375.684-1.993 1.435-1.433.667-.937 1.861-3.726 2.631-2.806 1.032-.916 2.297-3.799 3.105-2.473 1.795-.431 1.823-2.126 2.69-1.828.803.276.013 1.577.853 3.045 1.475-.542.59 2.476.813 3.818 1.43-.524.368 2.682.338 3.826 1.323-.05.809.894.158 1.98Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m16.29 22.043.724-2.11m-.725 2.11c2.374.815 8.19-1.25 6.365-2.009m-6.365 2.009c-2.393-.822-5.817-6.062-3.945-5.55M22.088 5.16l-1.933 5.628m1.933-5.628c-.868-.298-.896 1.397-2.69 1.828m2.69-1.828c.802.276.012 1.577.852 3.045m-5.926 11.727 5.64.102m-5.64-.102.886-2.579m-.886 2.58-4.67-3.44m10.31 3.541c.67-.849.945-1.117 1.595-2.204.65-1.087 1.165-2.03-.158-1.98M17.9 17.352l6.191-1.504M17.9 17.353l-4.238-5.086m4.238 5.086 1.115-3.247m5.076 1.743c.03-1.144 1.091-4.35-.338-3.826m-10.091.244c-.752-.56-1.241.058-1.436 1.433-.195 1.375.089 1.716.118 2.794m1.318-4.227c.666-.937 1.86-3.726 2.63-2.806m2.723 4.645 4.738-2.083m-4.738 2.083L16.292 9.46m2.723 4.645 1.14-3.318m3.598 1.235c-.224-1.342.662-4.36-.813-3.818m-6.648 1.256c1.032-.916 2.297-3.799 3.105-2.473m.758 3.8-.758-3.8m.758 3.8 2.785-2.583"
            stroke="var(--accent-dark)"
        />
        <path
            d="M17.255 21.413c.256 1.241.235 1.625.316 2.703 1.856-.676-1.03 4.779-3.33 5.784-2.32 1.013-8.385-.53-6.66-1.42-.75-.773-1.19-.832-1.976-1.977s-.844-1.931.088-2.027c-.143-1.14-1.15-4.003.04-3.846.14-1.372-.877-4.351.615-3.921 1.03-1.532-.095-2.8.746-3.166.777-.34 1.076 1.153 2.687 1.666.72-1.397 2.108 1.425 3.18 2.263.7-1.353 2.086 1.727 2.838 2.589.94-.931 1.2.111 1.456 1.352Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m14.24 29.9-.893-2.045m.894 2.045c2.3-1.005 5.186-6.46 3.33-5.784M14.24 29.9c-2.32 1.013-8.385-.53-6.66-1.42m-.487-14.938 2.382 5.453m-2.382-5.453c-.841.368.284 1.635-.746 3.167m.746-3.166c.777-.34 1.076 1.153 2.687 1.666m3.566 12.646 4.224-3.74m-4.224 3.74-1.092-2.499m1.092 2.5-5.765.624m9.989-4.364c-.082-1.078-.06-1.462-.316-2.703-.256-1.24-.515-2.283-1.456-1.352m-3.544 5.295 3.544-5.295m-3.544 5.295-6.562-.88m6.562.88-1.374-3.146m4.918-2.149c-.752-.862-2.138-3.942-2.837-2.59m-7.269 7.005c-.932.096-.874.883-.088 2.027.786 1.145 1.226 1.204 1.977 1.977m-1.889-4.004c-.143-1.14-1.15-4.003.04-3.846m5.148 1.58 2.08-4.738m-2.08 4.738-5.148-1.58m5.148 1.58-1.405-3.215m3.486-1.523c-1.073-.838-2.46-3.66-3.18-2.263M5.732 20.63c.14-1.372-.877-4.351.615-3.921m3.128 2.286-3.128-2.286m3.128 2.286.305-3.786"
            stroke="var(--accent-dark)"
        />
        <path
            d="M37.54 41.491c-.876-.028-1.135.03-1.88.098.243-1.3-3.14 1.218-3.557 2.847-.42 1.642 1.344 5.464 1.748 4.226.616.408.708.69 1.581 1.08.873.388 1.416.337 1.372-.288.796-.035 2.866.304 2.62-.461.92-.248 3.072.085 2.604-.849.925-.852 1.92-.253 2.073-.849.14-.55-.913-.578-1.452-1.582.869-.632-1.219-1.227-1.916-1.84.841-.613-1.423-1.178-2.1-1.576.526-.725-.216-.777-1.092-.806Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m32.103 44.436 1.5.358m-1.5-.358c.416-1.629 3.8-4.147 3.557-2.847m-3.557 2.847c-.42 1.642 1.344 5.464 1.748 4.226m10.25-1.367-4-.953m4 .953c-.152.596-1.148-.003-2.073.85m2.073-.85c.14-.55-.913-.578-1.452-1.582m-9.046-.92 2.057-3.204m-2.057 3.205 1.833.437m-1.833-.437.248 3.868m1.809-7.073c.745-.069 1.004-.126 1.88-.098.877.029 1.62.081 1.094.806m-3.198 2.934 3.198-2.934m-3.198 2.934 1.368 4.223m-1.368-4.224 2.307.55m.89-3.483c.677.398 2.941.963 2.1 1.577m-3.928 5.58c.043.625-.5.676-1.373.287-.873-.388-.965-.671-1.58-1.079m2.953.792c.795-.035 2.865.304 2.62-.461m-1.681-3.213 2.989-1.907m-2.99 1.907 1.681 3.213m-1.68-3.213 2.358.562m.63-2.468c.698.612 2.786 1.207 1.917 1.839m-3.225 3.28c.92-.248 3.072.085 2.604-.849m-1.926-1.802 1.926 1.802m-1.926-1.802 2.547-.629"
            stroke="var(--accent-dark)"
        />
        <path
            d="M40.546 39.686c-.366.556-.428.746-.675 1.255-.806-.67-.392 2.514.574 3.426.975.92 4.323 1.286 3.624.536.525-.238.758-.186 1.372-.598.615-.411.794-.783.342-1.001.29-.529 1.343-1.735.714-1.878.191-.693 1.272-1.954.438-2.019-.226-.935.582-1.343.229-1.677-.327-.308-.762.363-1.671.316-.096-.812-1.334.304-2.034.514-.093-.786-1.379.456-1.922.736-.296-.626-.625-.166-.99.39Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m40.445 44.367.84-.83m-.84.83c-.966-.912-1.38-4.096-.574-3.425m.574 3.425c.975.92 4.323 1.286 3.624.536m3.095-7.173-2.24 2.212m2.24-2.212c.353.334-.455.742-.229 1.677m.229-1.677c-.327-.308-.762.363-1.671.316m-4.208 5.491-1.413-2.596m1.413 2.596 1.027-1.014m-1.027 1.014 2.784 1.366m-4.198-3.962c.247-.509.31-.7.675-1.255.366-.556.695-1.016.99-.39m.776 3.227-.775-3.227m.775 3.227 3.471.78m-3.471-.78 1.292-1.276m-2.067-1.95c.543-.281 1.829-1.523 1.922-.737m2.324 4.744c.452.218.273.59-.342 1.001-.614.412-.847.36-1.372.598m1.714-1.6c.29-.528 1.343-1.734.714-1.877m-2.893-.18-.145-2.686m.145 2.687 2.893.18m-2.893-.18 1.32-1.305M43.46 38.56c.7-.21 1.938-1.326 2.034-.514m1.004 3.38c.191-.693 1.272-1.954.438-2.019m-2.01.535 2.01-.535m-2.01.535.568-1.896"
            stroke="var(--accent-dark)"
        />
        <path
            d="M34.948 42.835c-.023.659.023.853.078 1.413-1.047-.183.981 2.358 2.293 2.671 1.323.316 4.402-1.008 3.405-1.312.329-.462.557-.53.87-1.186.313-.656.272-1.064-.232-1.031-.027-.597.247-2.152-.37-1.967-.2-.691.07-2.307-.683-1.956-.686-.694-.204-1.442-.684-1.557-.444-.105-.466.686-1.275 1.09-.509-.652-.989.915-1.482 1.439-.494-.632-.95 1.068-1.27 1.576-.584-.395-.627.162-.65.82Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m37.32 46.92.288-1.127m-.288 1.126c-1.313-.313-3.34-2.854-2.294-2.671m2.293 2.671c1.323.316 4.402-1.008 3.405-1.312m-1.099-7.697-.768 3.003m.768-3.003c.48.115-.002.863.684 1.557m-.684-1.557c-.444-.105-.466.686-1.275 1.09m-.742 6.793-2.582-1.545m2.582 1.545.352-1.376m-.352 1.376 3.116-.186m-5.698-1.359c-.055-.56-.101-.754-.078-1.413.023-.658.066-1.215.65-.82m2.362 2.402-2.362-2.402m2.362 2.402 3.402-1.027m-3.402 1.027.443-1.733m-2.805-.67c.32-.507.776-2.207 1.27-1.575m4.494 2.951c.504-.033.545.375.232 1.03-.313.656-.541.725-.87 1.187m.639-2.217c-.028-.597.246-2.152-.371-1.967m-2.589 1.261-1.535-2.245m1.535 2.245 2.589-1.261m-2.589 1.261.454-1.77m-1.989-.475c.493-.524.973-2.091 1.482-1.439m2.642 2.423c-.2-.691.07-2.307-.683-1.956m-1.452 1.446 1.452-1.446m-1.452 1.446L38.35 39"
            stroke="var(--accent-dark)"
        />
        <path
            d="M30.852 44.22c-.946-.227-1.242-.223-2.068-.316.577-1.357-3.705.621-4.549 2.298-.85 1.69.148 6.238.885 4.984.571.58.603.909 1.458 1.526.856.618 1.458.684 1.56-.005.873.14 3.042.973 2.958.086 1.06-.063 3.318.78 3.033-.339 1.21-.719 2.149.154 2.457-.458.285-.567-.853-.833-1.197-2.045 1.095-.492-1.03-1.606-1.641-2.428 1.062-.478-1.263-1.598-1.902-2.183.745-.67-.049-.893-.994-1.12Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m24.235 46.203 1.544.724m-1.544-.724c.844-1.678 5.126-3.656 4.55-2.299m-4.55 2.298c-.85 1.69.148 6.238.885 4.984m11.466.81-4.117-1.931m4.117 1.93c-.308.614-1.247-.26-2.457.459m2.457-.458c.285-.567-.853-.833-1.197-2.045m-9.61-3.024 3.005-3.023m-3.005 3.023 1.887.885m-1.887-.885-.66 4.26m3.665-7.283c.826.093 1.122.089 2.068.316.945.227 1.739.45.994 1.12m-4.18 2.472 4.18-2.471m-4.18 2.47.473 4.896m-.473-4.895 2.376 1.114m1.804-3.586c.64.585 2.963 1.705 1.902 2.183m-5.61 5.184c-.102.69-.704.623-1.56.005-.855-.617-.887-.946-1.458-1.526m3.019 1.521c.872.14 3.04.973 2.957.086m-1.054-3.867 3.706-1.403m-3.706 1.403 1.054 3.867m-1.054-3.867 2.427 1.139m1.279-2.542c.61.822 2.736 1.936 1.64 2.428m-4.292 2.842c1.06-.063 3.318.78 3.033-.339m-1.66-2.39 1.66 2.39m-1.66-2.39 2.92-.113"
            stroke="var(--accent-dark)"
        />
        <path
            d="M24.254 40.038c-.673.68-.816.93-1.308 1.577-.935-1.157-1.279 3.414-.189 4.953 1.099 1.551 5.684 2.97 4.921 1.73.805-.192 1.116-.056 2.096-.466.98-.411 1.338-.884.77-1.311.558-.662 2.382-2.067 1.543-2.437.468-.92 2.347-2.395 1.199-2.711-.047-1.37 1.204-1.725.806-2.287-.369-.52-1.172.302-2.433-.01.1-1.163-1.956.067-2.997.172.096-1.127-2.064.265-2.907.512-.233-.957-.827-.402-1.5.278Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m22.757 46.568 1.417-.935m-1.417.935c-1.09-1.539-.746-6.11.189-4.953m-.189 4.953c1.099 1.551 5.684 2.97 4.921 1.73m6.414-9.212-3.778 2.494m3.778-2.494c.398.562-.853.916-.806 2.287m.806-2.287c-.369-.52-1.172.302-2.433-.01m-7.485 6.557-1.228-4.018m1.228 4.018 1.732-1.143m-1.732 1.143 3.504 2.665m-4.732-6.683c.492-.647.635-.897 1.308-1.577.674-.68 1.268-1.235 1.501-.278m.15 4.73-.15-4.73m.15 4.73 4.639 2.03m-4.638-2.03 2.18-1.44m-2.33-3.29c.842-.247 3.002-1.64 2.906-.512m1.882 7.273c.568.427.21.9-.77 1.31-.98.411-1.29.275-2.096.467m2.866-1.777c.558-.662 2.382-2.067 1.543-2.437m-4.001-1.034.576-3.802m-.576 3.803 4 1.033m-4-1.034 2.228-1.47m-1.652-2.332c1.041-.105 3.098-1.335 2.997-.172m.428 5.008c.468-.92 2.347-2.395 1.199-2.711m-2.972.207 2.972-.207m-2.972.207 1.345-2.504"
            stroke="var(--accent-dark)"
        />
        <path
            d="M24.25 39.224c-.651-1.05-.927-1.309-1.596-2.129 1.825-.731-3.99-2.726-6.365-1.939-2.393.794-5.817 5.852-3.945 5.358-.03 1.04-.313 1.37-.118 2.697s.684 1.924 1.435 1.383c.667.905 1.861 3.598 2.631 2.71 1.032.884 2.297 3.667 3.105 2.387 1.795.417 1.823 2.053 2.69 1.765.803-.266.013-1.523.853-2.94 1.475.523.59-2.39.813-3.686 1.43.506.368-2.59.338-3.694 1.323.048.809-.863.158-1.912Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m16.29 35.156.724 2.038m-.725-2.038c2.374-.787 8.19 1.208 6.365 1.94m-6.365-1.94c-2.393.794-5.817 5.852-3.945 5.358m9.744 10.942-1.933-5.433m1.933 5.433c-.868.288-.896-1.348-2.69-1.765m2.69 1.765c.802-.266.012-1.523.852-2.94m-5.926-11.322 5.64-.099m-5.64.099.886 2.49m-.886-2.49-4.67 3.32m10.31-3.419c.67.82.945 1.079 1.595 2.129s1.165 1.96-.158 1.912M17.9 39.684l6.191 1.452M17.9 39.684l-4.238 4.91m4.238-4.91 1.115 3.135m5.076-1.683c.03 1.104 1.091 4.2-.338 3.694m-10.091-.236c-.752.54-1.241-.056-1.436-1.383-.195-1.328.089-1.657.118-2.697m1.318 4.08c.666.905 1.86 3.598 2.63 2.71m2.723-4.485 4.738 2.01m-4.738-2.01-2.723 4.485m2.723-4.485 1.14 3.204m3.598-1.193c-.224 1.296.662 4.209-.813 3.686m-6.648-1.212c1.032.884 2.297 3.667 3.105 2.387m.758-3.668-.758 3.668m.758-3.668 2.785 2.493"
            stroke="var(--accent-dark)"
        />
        <path
            d="M17.255 35.764c.256-1.198.235-1.568.316-2.61 1.856.653-1.03-4.613-3.33-5.584-2.32-.978-8.385.512-6.66 1.371-.75.747-1.19.804-1.976 1.909s-.844 1.864.088 1.957c-.143 1.101-1.15 3.864.04 3.713.14 1.325-.877 4.201.615 3.786 1.03 1.48-.095 2.702.746 3.057.777.328 1.076-1.113 2.687-1.609.72 1.349 2.108-1.376 3.18-2.185.7 1.307 2.086-1.667 2.838-2.5.94.9 1.2-.107 1.456-1.305Z"
            fill="var(--accent-darker)"
        />
        <path
            d="m14.24 27.57-.893 1.974m.894-1.974c2.3.97 5.186 6.237 3.33 5.585m-3.33-5.585c-2.32-.978-8.385.512-6.66 1.371m-.487 14.422 2.382-5.264m-2.382 5.264c-.841-.355.284-1.578-.746-3.057m.746 3.057c.777.328 1.076-1.113 2.687-1.609m3.566-12.21 4.224 3.61m-4.224-3.61-1.092 2.413m1.092-2.413-5.765-.603m9.989 4.214c-.082 1.04-.06 1.411-.316 2.61-.256 1.197-.515 2.204-1.456 1.304m-3.544-5.112L15.8 37.07m-3.544-5.112-6.562.85m6.562-.85-1.374 3.038m4.918 2.075c-.752.832-2.138 3.806-2.837 2.5m-7.269-6.763c-.932-.093-.874-.852-.088-1.957s1.226-1.162 1.977-1.909m-1.889 3.866c-.143 1.101-1.15 3.864.04 3.713m5.148-1.525 2.08 4.574m-2.08-4.574L5.733 36.52m5.148-1.525-1.405 3.104m3.486 1.47c-1.073.81-2.46 3.533-3.18 2.185M5.732 36.52c.14 1.325-.877 4.201.615 3.786m3.128-2.207-3.128 2.207m3.128-2.207.305 3.655"
            stroke="var(--accent-dark)"
        />
    </svg>
);
