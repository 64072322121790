import React from "react";
import { classnames } from "../../utils/classnames";
import styles from "./CircleText.module.scss";

/**
 *
 * @param {object} obj
 * @param {string} [obj.tag]
 * @param {string} obj.children
 *
 * @returns
 */
export const CircleText = ({
    tag: Tag,
    children,
    className,
    charContainerClassName,
    size,
    onCharClick,
    ...props
}) => {
    return typeof children !== "string" ? null : (
        <Tag
            {...props}
            className={classnames(className, styles.paragraph)}
            style={{
                "--num-char": children.split("").length,
                "--radius": size,
            }}
        >
            {children.split("").map((char, index) => (
                <CharSpan
                    key={index}
                    index={index}
                    onCharClick={onCharClick}
                    charContainerClassName={charContainerClassName}
                >
                    {char}
                </CharSpan>
            ))}
        </Tag>
    );
};

const CharSpan = ({ children, index, charContainerClassName, onCharClick }) => (
    <span
        className={classnames(styles["char-container"], charContainerClassName)}
        style={{ "--i": index }}
        onClick={() => {
            onCharClick && onCharClick(index);
        }}
    >
        <span className={styles.char}>{children}</span>
    </span>
);
