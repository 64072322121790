import React from "react";
import { Star } from "../../svgs/Star";
import { getRandomArbitrary } from "../../utils/random";

// typing for the params
interface Props {
    className?: string;
    id?: string;
    props?: object;
}

// typing for the function
type Fun = (props: Props) => JSX.Element | null;

export const Stars: Fun = ({ className, ...props }) => {
    const RandomStar = ({ width = `${getRandomArbitrary(1, 3)}px` }) => (
        <Star
            className="star"
            style={{
                width,
                position: "absolute",
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                transform: `rotate(${getRandomArbitrary(-1, 1) * 90}deg)`,
                fill: "var(--accent-2)",
            }}
        />
    );

    const bigStars = new Array(10).fill(1);
    const littleStars = new Array(100).fill(1);

    return (
        <div className={className} {...props}>
            {bigStars.map((e, i) => (
                <RandomStar key={i} width={`${getRandomArbitrary(4, 10)}px`} />
            ))}
            {littleStars.map((e, i) => (
                <RandomStar key={i} width="3px" />
            ))}
        </div>
    );
};
