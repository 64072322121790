export const classnames = (...classArgs) => {
    const classes = [];

    classArgs.forEach((classEl) => {
        const classType = typeof classEl;

        if (["string", "number"].includes(classType)) {
            classes.push(classEl);
        } else if (Array.isArray(classEl)) {
            classEl.forEach((el) => classes.push(el));
        } else if (classType === "object") {
            Object.entries(classEl).forEach(([classKey, classValue]) => {
                if (classValue) classes.push(classKey);
            });
        }
    });

    return classes.map((el) => `${el}`).join(" ");
};
