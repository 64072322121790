import React, { useState } from "react";
import { Leafy } from "../Leafy/Leafy";

export const LeafyRoof = () => {
    const [leafsKilled, setLeafsKilled] = useState(0);
    const middle = "40%";
    const leafyBuddies = [
        {
            height: "70vh",
            top: " -3vh",
            right: `calc(${middle} + 100vh)`,
            transform: "rotate(190deg)",
        },
        {
            height: "50vh",
            top: " -6vh",
            right: `calc(${middle} + 80vh)`,
            transform: "rotate(170deg)",
        },
        {
            height: "69vh",
            top: " -2.5vh",
            right: `calc(${middle} + 50vh)`,
            transform: "rotate(180deg) scaleX(-1)",
        },
        {
            height: "30vh",
            top: " -6vh",
            right: `calc(${middle} + 40vh)`,
            transform: "rotate(180deg) scaleX(-1)",
        },
        {
            height: "60vh",
            top: " -6vh",
            right: `calc(${middle} + 17vh)`,
            transform: "rotate(180deg)",
        },
        {
            height: "40vh",
            top: " -5vh",
            right: `${middle}`,
            transform: "rotate(180deg) scaleX(-1)",
        },
        {
            height: "40vh",
            top: " -10vh",
            right: `calc(${middle} - 16vh)`,
            transform: "rotate(180deg)",
        },

        {
            height: "50vh",
            top: " -2vh",
            right: `calc(${middle} - 35vh)`,
            transform: "rotate(180deg) scaleX(-1)",
        },
        {
            height: "55vh",
            top: " -2vh",
            right: `calc(${middle} - 70vh)`,
            transform: "rotate(180deg)",
        },
        {
            height: "80vh",
            top: " -3vh",
            right: `calc(${middle} - 100vh)`,
            transform: "rotate(180deg) scaleX(-1)",
        },
    ];

    const onLeafMurder = () => {
        if (!leafsKilled) {
            console.log(
                "Congratulations, you just killed a leaf -_-\nI hope you're happy."
            );
        }
        setLeafsKilled((prev) => ++prev);
    };

    // Add all the leafys
    return leafyBuddies.map((leafStyles, i) => (
        <Leafy
            key={i}
            style={{
                position: "absolute",
                ...leafStyles,
            }}
            onLeafMurder={onLeafMurder}
        />
    ));
};
