import React, { useEffect, useState } from "react";
import { CircleText } from "../../components/CircleText";

import styles from "./Home.module.scss";
import { classnames } from "../../utils/classnames";
import { MagicalLeafICorn } from "../../svgs/MagicalLeaf-i-corn";
import { LeafyRoof } from "../../components/LeafyRoof.js/LeafyRoof";

export const Home = () => {
    const [caughtMyName, handleNameCharClick] = useCatchingSara();

    return (
        <div className={styles.pumpkin}>
            <CircleText
                tag="h1"
                className={styles["main-event"]}
                charContainerClassName={styles["char-container"]}
                onCharClick={handleNameCharClick}
            >
                Sara Dhani&bull;Software Engineer&bull;
            </CircleText>

            <CircleText
                tag="p"
                className={classnames(styles["inner-circle"], "heading")}
            >
                I am a full-stack software engineer and frontend aficionado who
                loves to learn new things.
            </CircleText>

            {caughtMyName && (
                <MagicalLeafICorn
                    className={styles["magical-leaf-i-corn"]}
                    stroke="var(--accent-light)"
                    eyelidClassName={styles["eye-lids"]}
                />
            )}

            <div>
                <LeafyRoof />
            </div>
        </div>
    );
};

/* Custom hook to handle all the sara catching logic */
const useCatchingSara = () => {
    const [lettersCaught, setLettersCaught] = useState([0, 0, 0, 0]);
    const [caughtMyName, setCaughtMyName] = useState();

    useEffect(() => {
        if (lettersCaught.reduce((sum, el) => sum + el) >= 4) {
            setCaughtMyName(true);
        }
    }, [lettersCaught]);

    const handleNameCharClick = (index) => {
        if (index >= 0 && index <= 4 && !lettersCaught[index]) {
            console.log("hi", index);
            setLettersCaught((prev) => {
                prev[index] = 1;
                return [...prev];
            });
        }
    };

    useEffect(() => {
        let t;
        if (caughtMyName) {
            t = setTimeout(() => {
                setCaughtMyName(false);
                setLettersCaught([0, 0, 0, 0]);
            }, 10000);
        }
        return () => {
            clearTimeout(t);
        };
    }, [caughtMyName]);

    return [caughtMyName, handleNameCharClick];
};
