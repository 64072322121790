import { CodePenLogo } from "../../svgs/CodePenLogo";
import { GitHubLogo } from "../../svgs/GitHubLogo";
import { LinkedInLogo } from "../../svgs/LinkedInLogo";
import React from "react";
import { SunshineToggle } from "../SunshineToggle/SunshineToggle";
import styles from "./Footer.module.scss";

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className={styles["footer"]}>
            <a
                href="https://github.com/blinkous"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
                title="GitHub Profile"
            >
                <GitHubLogo />
            </a>
            <a
                href="https://www.linkedin.com/in/sara-dhani/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
                title="LinkedIn Profile"
            >
                <LinkedInLogo />
            </a>
            <a
                href="https://codepen.io/blinkous/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
                title="CodePen Profile"
            >
                <CodePenLogo />
            </a>

            <SunshineToggle className={styles.link} />

            <p>&copy; {currentYear} Sara Dhani, All rights reserved.</p>
        </div>
    );
};
