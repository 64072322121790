import React, { createContext, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import "./styles/global.scss";
import * as serviceWorker from "./serviceWorker";
import { Home } from "./pages/Home";
import { Stars } from "./components/Stars";
import { Footer } from "./components/Footer";

const container = document.querySelector("#root");
const root = createRoot(container);

const value = {
    isNight: window.matchMedia("(prefers-color-scheme: dark)")?.matches,
    setIsNight: () => {},
};

export const NightContext = createContext(value);

const App = () => {
    const [isNight, setIsNight] = useState(value.isNight);

    useEffect(() => {
        const sunshine = "sunshine-time";
        const nightTime =
            "a-certain-kind-of-darkness-is-needed-to-see-the-stars-sometimes";

        const classList = document.body.classList;

        if (isNight) {
            classList.add(nightTime);
            classList.remove(sunshine);
        } else {
            classList.remove(nightTime);
            classList.add(sunshine);
        }
    }, [isNight]);

    return (
        <NightContext.Provider value={{ isNight, setIsNight }}>
            <div className="home-is-where-your-dev-machine-is">
                <Home />
                <Footer />
            </div>
        </NightContext.Provider>
    );
};

root.render(
    <>
        <Stars />
        <App />
    </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
