import React from "react";

export const Star = ({ fill = "#fff", ...props }) => {
    return (
        <svg
            viewBox="0 0 59 96"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill={fill}
        >
            <path d="M58.5 50s-19.61 1.701-19.815 2.5a9.936 9.936 0 0 1-.857 2.2C37.377 55.548 47 68.5 47 68.5s-12.047-10.424-12.73-10A9.952 9.952 0 0 1 32 59.542C31.053 59.84 29 96 29 96s-2.053-36.16-3-36.458a9.952 9.952 0 0 1-2.27-1.042c-.753-.468-11.73 10-11.73 10s8.719-12.842 8.338-13.5a9.94 9.94 0 0 1-1.023-2.5C19.109 51.701.5 50 .5 50s18.61-1.701 18.815-2.5a9.94 9.94 0 0 1 1.023-2.5c.334-.577-8.838-12-8.838-12s11.397 9.017 12.23 8.5A9.952 9.952 0 0 1 26 40.458C26.947 40.16 29 .5 29 .5s2.053 39.66 3 39.958a9.952 9.952 0 0 1 2.27 1.042C35.103 42.017 47 33 47 33s-9.54 11.609-9.172 12.3c.368.689.658 1.426.857 2.2.206.799 19.815 2.5 19.815 2.5Z" />
        </svg>
    );
};
